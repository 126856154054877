import PropTypes from 'prop-types'

import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

import OrganizeTitle from '../OrganizeTitle'

const TabContentHeader = ({ total, counterSubText, isTabExist = true }) => {
  //@ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  return (
    <>
      {isOrganizeMode
        ?
          <OrganizeTitle
            organizeTitle={
            selectedItems?.length > 0 ? `${selectedItems?.length} ${labels?.Collections.selectedCountHeadingLabel}` :
              labels?.Collections.organiseSectionTitleLabel}
          />
        :
        isTabExist && <div className='text-paragraph-2 color-darkgrey mb-7 h4'>{total} {counterSubText}</div>
      }
    </>
  )
}

TabContentHeader.propTypes = {
  total: PropTypes.number,
  counterSubText: PropTypes.string,
  isTabExist: PropTypes.bool
}

export default TabContentHeader
