import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal, Icon } from '@mch-group/uikit-components'
import { px2rem, mq } from '@utils'

const ModalTopBar = ({ className, onClick, title }) => (
  <Modal.Body className={`${className}`}>
    <h2 className='h4'>{title}</h2>
    <Icon onClick={onClick} name='close' color='var(--bs-mch-gray-500)' className='close-icon' />
  </Modal.Body>
)

const ModalTopBarStyled = styled(ModalTopBar)`
  padding: ${px2rem(23)} ${px2rem(23)} ${px2rem(20)} !important;
  display: flex !important;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${px2rem(10)};
  border-bottom: 1px solid var(--bs-mch-galleries) !important;
  ${mq.lessThan('largestTablet')`
    &.header:not(.ui) {
      padding-right: ${px2rem(16)} !important;
    }
  `}
  .close-icon {
    cursor: pointer;
  }
`

ModalTopBar.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  title: PropTypes.string,
  onClick: PropTypes.func
}

export default ModalTopBarStyled
