/** This file has the classic Context/Provider structure */
import { FC } from 'react'

import { createContext, useContext } from 'react'
import type IAppProps from 'types/IAppProps'

const PageContext = createContext<IPageContextStore>({
  page: {}
} as IPageContextStore)

export const useModuleContext = () => useContext(PageContext)

/**
 * @returns {JSX.Element}
 * @constructor
 */

const PageProvider: FC<IAppProvider> = ({ children, state }) => (
  <PageContext.Provider value={{ page: state }}>{children}</PageContext.Provider>
)

interface IPageContextStore {
  page: IAppProps
}

interface IAppProvider {
  state: any
}

export default PageProvider

export { PageContext }