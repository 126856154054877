/*
TODO:
  - convert to typescript
  - remove old styled components media queries
  - remove sui styles
*/
import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import styled from 'styled-components'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { mq, px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import removeAllCookies from '@utils/removeCookies'

const TabsButtons = ({ subscribedCollectionsItems }) => {
  const [{ myCollectionPageActiveTab, isOrganizeMode, labels }, dispatch] = useMyCollectionsPageContext()

  const btnProps = (key) => ({
    className: `label label-2 ui button ${myCollectionPageActiveTab === key || (!myCollectionPageActiveTab && key === 'myCollections') ? 'active' : ''}`,
    opacity: 1,
    onClick: () => handleTabClick(key)
  })

  const handleTabClick = (tab) => {
    dispatch({ type: 'SET_MY_COLLECTION_ACTIVE_TAB', payload: tab })
    const newUrl = `${window.location.pathname}?activeCollectionTab=${tab}`
    Router.push({
      pathname: '/',
      query: { activeCollectionTab: tab }
    }, newUrl, { shallow: true })
  }

  const tabsData = [{ id: 'myCollections', name: 'myCollectionsTabLabel' }]

  if (subscribedCollectionsItems?.length > 0) {
    tabsData.push({
      id: 'followingCollections', name: 'followingCollectionTabLabel'
    })
  }

  // clear storage
  React.useEffect(() => {
    removeAllCookies()
  }, [])

  return (
    <TabsWrapper>
      <TabsContainer>
        {tabsData.map((data) => (
          <FilterButton
            key={data.id}
            {...btnProps(data.id)}
            disabled={isOrganizeMode}
          >
            {labels?.Collections[data.name] || data.name}
          </FilterButton>
        ))}
      </TabsContainer>
    </TabsWrapper>
  )
}

TabsButtons.propTypes = {
  subscribedCollectionsItems: PropTypes.array
}

const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${px2rem(40)};
  ${mq.lessThan('desktop')`
    width: 100vw;
    margin-left: -17.7778px !important;
  `}

  ${mq.lessThan('largestTablet')`
    margin-bottom: ${px2rem(20)};
  `}
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  max-width: 100%;
  ${mq.lessThan('desktop')`
    padding-left: 17.7778px;
    padding-bottom: 5px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ${mq.lessThan('largestTablet')`
     justify-content: flex-start;
     align-items: flex-start;
  `}
`

const FilterButton = styled.h2`
  &.ui.button {
    border-radius: ${px2rem(45)};
    background: ${tokens.color.base.primary.white.value};
    text-align: center;
    padding: ${px2rem(8)} ${px2rem(30)};
    margin-right: ${px2rem(10)};
    border: 1.5px solid ${({ active }) => (active ? 'var(--bs-mch-gray-500)' : 'var(--bs-mch-galleries)')};
    transition: all 0.25s ease;
    color: var(--bs-mch-gray-500) !important;
    opacity: ${({ opacity }) => (opacity ? 1 : 0)};
    font-size: ${tokens.fontSize[1].value};
    font-weight: ${tokens.fontWeights.artBaselText1.value};
    font-family: "Art Basel Text", Arial, Helvetica, sans-serif;
    letter-spacing: normal;

    &.active {
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    }

    &:hover {
      background-color: ${({ active }) => (active ? 'var(--bs-mch-galleries)' : tokens.color.base.primary.white.value)} !important;
      height: unset !important;
      max-height: unset !important;
      border: 1.5px solid var(--bs-mch-gray-400) !important;
      color: inherit !important;
    }
  }

  ${mq.lessThan('largestTablet')`
     padding: ${px2rem(8)} ${px2rem(15)};
  `}
`

export default TabsButtons
