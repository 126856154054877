import { useCallback, useState, useEffect, cloneElement, useContext } from 'react'
import styled from 'styled-components'
import { withFormik, FormikProps, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { getSiteLanguage } from '@utils'
import { LoginContext } from '@providers/Login'
import { logger, validateAxiosResponse } from '@dmi-mch/utils'
import { Contact as ContactService, VipInvitation as VipInvitationService } from '@services'
import { Button } from '@mch-group/uikit-components'
import validationConstants from '@constants/validation'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import preferredContactOption from './contactOptions'
import EnquiryGlobalStyles from './styles/EnquiryGlobalStyles'
import EnquiryStyles from './styles/EnquiryStyles'
import type { IUserRedux } from '../../types/IReduxStoreTypes'

// const mID = 'modal-artwork-contact'
const maxCharsInText = 750

const EnquiryModalForm = ({
  // className,
  contactBtnClass,
  // imgAlt,
  // image,
  // title,
  // subtitle,
  eventId,
  // isSubmitting,
  showInRoom,
  setUserDataFromAPI,
  // isShowArtworkDetail = true,
  // Default button, in case user doesn't enter any
  button = (
    <Button variant='primary' size='compact' className={contactBtnClass || ''}>
      Inquiry
    </Button>
  ),
  status = {
    isOpenContactForm: false
  },
  setStatus,
  onShowModal,
  showWhenCatalog,
  showWhenExhibition,
  showWhenShowroom,
  // submitButtonAttributes,
  initialValues,
  buttonOnLoad,
  buttonOnLoadLabel,
  showPopupWithoutButton,
  // closeCallback,
  // This is callback to decide what happens after Login modal is closed.
  closeLoginModalCallback
}: ArtworkContactFormProps & FormikProps<FormValues>) => {
  const {
    // values,
    // touched,
    // errors,
    // handleChange,
    // handleBlur,
    // handleSubmit,
    setFieldValue
  } = useFormikContext<FormValues>()
  // User
  const [user, setUser] = useState<IUserRedux | null>(null)
  const { showLogin } = useContext(LoginContext)

  // Labels
  const { labels } = useLabelsFromAPI(['ArtworkContactForm', 'FieldValidation', 'AltTags'], getSiteLanguage())
  const { ArtworkContactForm: artworkContactFormLabels } = labels || {}
  const contactOptions = preferredContactOption(artworkContactFormLabels)
  // Component can be enabled or disabled according to some logic in the CMS
  const [isComponentEnabled, setIsComponentEnabled] = useState(false)
  // const [userInvitations, setUserInvitations] = useState<Array<ABTypes.Invitation.UserInvitation> | null>(null)
  const [isAllReady, setIsAllReady] = useState(false)

  //disable the phone number field when preferred option EMAIL is selected.
  const isPhoneNumberDisableAndBlank = initialValues.preferredContactOption === contactOptions[0].value

  const getUser = useCallback(async () => {
    try {
      setUserDataFromAPI()
    } catch (e) {
      logger(e)
    }
  }, [setUserDataFromAPI])

  useEffect(() => {
    if (user?.me && user.me.telephone && status.isOpenContactForm) {
      isPhoneNumberDisableAndBlank
        ? setFieldValue('contactNumber', '')
        : setFieldValue('contactNumber', user.me.telephone)
    }
  }, [setFieldValue, user, status.isOpenContactForm, isPhoneNumberDisableAndBlank])

  useEffect(() => {
    if (status.isOpenContactForm === false) {
      setIsAllReady(false)
    }
  }, [status.isOpenContactForm])

  // const onClickCloseIcon = () => {
  //   if (showPopupWithoutButton) {
  //     typeof closeCallback === 'function' && closeCallback()
  //     setStatus({ isOpenContactForm: false })
  //   }
  //   else {
  //     setStatus({ isOpenContactForm: false })
  //   }
  // }
  const guessIfIsEnabled = useCallback(async () => {
    try {
      if (eventId) {
        if (showInRoom) {
          setIsComponentEnabled(showWhenShowroom)
        } else {
          setIsComponentEnabled(showWhenExhibition)
        }
      } else if (showWhenCatalog) {
        setIsComponentEnabled(true)
      }
    } catch (error) {
      logger(error)
    }
  }, [eventId, showInRoom, showWhenShowroom, showWhenCatalog, showWhenExhibition])

  const getInvitationGEDs = useCallback(async () => {
    try {
      const response = await VipInvitationService.mineLatest({ limit: 3, mainEvent: true })
      if (validateAxiosResponse(response)) {
        // @ts-ignore
        // const arrayInvitations = response.data.map(item => `${item.packageName}, ${item.showFullName}`)
        // setUserInvitations(arrayInvitations)
      }
    } catch (error) {
      logger(error)
    }
  }, [])

  // What to do after click in the "Get in contact" button
  const onClickMainButton = useCallback(
    event => {
      event?.preventDefault()
      showLogin({
        onLoginSuccessCallback: (currentUser) => {
          setUser(currentUser)
          setStatus({ isOpenContactForm: true })
          if (typeof onShowModal === 'function') {
            onShowModal()
          }
        },
        onLoginModalCloseCallback: () => {
          if (typeof closeLoginModalCallback === 'function') {
            closeLoginModalCallback()
          }
        }
      })
    },
    [showLogin, onShowModal, setStatus, closeLoginModalCallback]
  )

  /**
   * @note This should be done with closures, the button prop should be a function
   * that contains the onClickMainButton, so when you call the component you could do a:
   * ``
   * <EnquiryModal
   *  button={(onClickMainButton) => <MyCustomButton onClick={onClickMainButton} />}
   *  />
   *  ``
   *  And inside the component the closure is done doing:
   *  ``
   *  const buttonWithVitamins = button(onClickMainButton)
   *  ``
   */
  // Add the onclick functionality to the button, which initially doesn't have functionality.
  const buttonWithVitamines = element => cloneElement(element, {
    onClick: (e) => { onClickMainButton(e) }
  })

  useEffect(() => {
    let isUnmounted = false
    !isUnmounted && guessIfIsEnabled()

    return () => {
      isUnmounted = true
    }
  }, [guessIfIsEnabled])

  useEffect(() => {
    if (showPopupWithoutButton) {
      // @ts-ignore
      typeof onClickMainButton === 'function' && onClickMainButton()
    }
    // Next line should document why this is necessary to "hack" react natural behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopupWithoutButton])

  useEffect(() => {
    let isUnmounted = false
    // Calculate GEDS only when the contact button is clicked
    if (!isUnmounted && status.isOpenContactForm) {
      // Triggers a +1 right after modal is shown
      if (!isAllReady) {
        !user?.me && getUser()
        getInvitationGEDs()
        setIsAllReady(true)
      }
    }

    return () => {
      isUnmounted = true
    }
  }, [getInvitationGEDs, status.isOpenContactForm, onShowModal, getUser, isAllReady, user])

  if (!artworkContactFormLabels) return null

  if (isComponentEnabled) {
    return (
      <>
        {!showPopupWithoutButton && buttonWithVitamines(button)}
        <EnquiryGlobalStyles />
      </>
    )
  }
  if (buttonOnLoad) {
    return (
      <Button
        className='sales-inquiry-button'
        type='button'
      >
        {buttonOnLoadLabel}
      </Button>
    )
  }

  return null
}

interface FormValues {
  allowJobTitle: boolean,
  allowShowHistory: boolean,
  text: string,
  preferredContactOption: string,
  contactNumber: string
}

const FormRules = withFormik<ArtworkContactFormProps, FormValues>({
  mapPropsToValues: () => ({
    allowJobTitle: true,
    allowShowHistory: true,
    text: '',
    preferredContactOption: 'EMAIL',
    contactNumber: ''
  }),
  validationSchema: props =>
    Yup.object().shape({
      text: Yup.string().max(maxCharsInText),
      preferredContactOption: Yup.string()
        .required(props.labels.FieldValidation.RequiredFieldMessage),

      contactNumber: Yup.string()
        .when('preferredContactOption', {
          is: 'EMAIL',
          then: () => Yup.string(),
          otherwise: () => Yup.string().required(props.labels.FieldValidation.RequiredFieldMessage)
        })
        .matches(validationConstants.PHONE, 'Please use the following format: +41 43 123 45 67')
    }),

  handleSubmit: async (values, { setSubmitting, setStatus, props, resetForm }) => {
    let reqParams: any = {
      allowJobTitle: values.allowJobTitle,
      allowShowHistory: values.allowShowHistory,
      artworkId: props.id,
      text: values.text,
      preferredContactOption: values.preferredContactOption
    }
    if (values.contactNumber && values.preferredContactOption !== 'EMAIL') {
      reqParams.contactNumber = values.contactNumber
    }

    if (values.text) { reqParams.text = values.text }
    //if user is contacting with gallery.
    if (props.isConnectToGallery) {
      reqParams = {
        ...reqParams,
        eventId: props.eventId,
        accountId: props.accountId
      }
    }


    const contact = !props.isConnectToGallery
      ? await ContactService.contactFormArtwork(reqParams)
      : await ContactService.contactFormGallery(reqParams)

    if (contact.ok) {
      // tracking prop
      if (typeof props.onSubmitSuccess === 'function') {
        props.onSubmitSuccess()
      }
      // Close contact form modal
      setStatus({ isOpenContactForm: false })
      // Open success popup
      setStatus({ isSuccessMessageOpened: true })
    }

    setSubmitting(false)
    resetForm()
  },
  displayName: 'EnquiryModalForm'
})(EnquiryModalForm)

const WrapperForLabels = (props) => {
  const { labels } = useLabelsFromAPI(['FieldValidation'], getSiteLanguage())
  const newProps = { ...props }
  newProps.labels = labels
  if (!newProps.labels) return null
  return (<FormRules {...newProps} />)
}

const EnquiryModalWrapper = styled(WrapperForLabels)`
  ${EnquiryStyles}
`


type ArtworkContactFormProps = {
  id?: number,
  title?: string,
  isShowArtworkDetail?: boolean,
  isConnectToGallery?: boolean,
  subtitle?: string,
  className?: string,
  image?: string,
  imgAlt?: string,
  user?: IUserRedux | null,
  isSubmitting?: boolean,
  showWhenCatalog: boolean,
  showWhenExhibition: boolean,
  showWhenShowroom: boolean,
  showInRoom?: boolean,
  eventId?: number,
  submitBtnAttrs?: {
    'data-event': string,
    'data-category': string,
    'data-action': string,
    'data-label': string,
    'data-value': string
  },
  setFieldValue?: Function,
  initialValues?: any,
  accountId?: number,
  closeCallback?: Function,
  onSubmitSuccess?: Function,
  onSuccessMessageClickOK?: Function,
  visible?: boolean,
  isSuccessMessageShow?: boolean,
  labels?: { [labelKey: string]: string },
  onShowModal?: Function,
  contactBtnClass: string,
  button: any,
  submitButtonAttributes: any,
  loginData: any,
  setUserDataFromAPI: any,
  artworkContactFormLabels: any,
  buttonOnLoad: any,
  buttonOnLoadLabel: any,
  showPopupWithoutButton: any,
  closeLoginModalCallback: Function
}

export default EnquiryModalWrapper
