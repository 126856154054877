import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Loader } from '@components'
import { Row, Col } from '@mch-group/uikit-components'

import { px2rem } from '@utils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import TabContentHeader from '@components/collections/edit-preview-collection/Tabs/TabContentHeader'
import EventCardList from '@components/ot-modules/Events/components/EventCardList'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'

const EventsTab = (props) => {
  const {
    editPreviewCollectionItems,
    totalItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    isLoadingMore,
    size,
    isTabExist
  } = props

  //@ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { prepareUnavailableEventData, onSelectCard } = useCollectionUtils()

   const isIntersectionActive =
    editPreviewCollectionItems &&
    !editPreviewCollectionItemsValidating &&
    !isRefreshing &&
    !isEmpty &&
    !isLoadingInitialData &&
    !isLoadingMore

  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels.Collections.totalEventsLabel}
        isTabExist={isTabExist}
      />
      {editPreviewCollectionItems.length > 0 ? (
        <>
          <Row>
            {editPreviewCollectionItems?.map((event) => {
                const isEventNotAvailable = Object.values(event.status).some((item) => item === true)
                let eventItemObjForUnavailableEvent = isEventNotAvailable && prepareUnavailableEventData(event)
                return (
                  <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={3} className='mb-lg-8 mb-md-8 d-flex' key={event.subEntityId}>
                    <EventCardList
                      item={!isEventNotAvailable ? event.item : eventItemObjForUnavailableEvent}
                      isOrganizeMode={isOrganizeMode}
                      isCollectionCard
                      entityType={event.entityType}
                      subEntityId={event.subEntityId}
                      onSelectCard={onSelectCard}
                      unavailableToView={isEventNotAvailable}
                      notAvailableLabel={labels.Collections.notAvailableForViewLabel}
                      isSelectedInCollections={
                        typeof selectedItems?.find((x) => x.entityId?.toString() === (event?.id?.toString() || event?.entityId?.toString())) !== 'undefined'
                      }
                    />
                  </Col>
                )
              })}
            <IntersectionCapture
              onIntersect={() => setSize(size + 1)}
              active={isIntersectionActive}
              params={{ rootMargin: '400px' }}
            />
          </Row>
        </>
      ) : null}
      {(isLoadingInitialData || isLoadingMore) && (
        <LoaderContainer>
          <Loader active />
        </LoaderContainer>
      )}
    </div>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${px2rem(100)};
`

EventsTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isOrganizeMode: PropTypes.bool,
  totalItems: PropTypes.number,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isTabExist: PropTypes.bool
}
export default EventsTab
