import { useState, createContext } from 'react'
import { Snackbar, SnackbarContainer } from '@mch-group/uikit-components'

const SnackBarNotificationContext = createContext<ISnackbarContext>({} as ISnackbarContext)

const SnackBarNotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [otherContent, setOtherContent] = useState<ISnackbarContent>({})

  const showNotification = (isShow: boolean, params: ISnackbarContent) => {
    const paramsCopy = { ...params }
    setShow(isShow)
    setOtherContent(paramsCopy)
  }

  return (
    <SnackBarNotificationContext.Provider value={{ showNotification }}>
      {show && (
        <SnackbarContainer
          className={otherContent.className}
          // @ts-ignore
          position={otherContent.position || 'top-end'}
        >
          <div aria-live='polite' aria-atomic='true' className='position-relative' style={otherContent.style}>
            <Snackbar
              message={otherContent.message || ''}
              autohide={otherContent.autohide}
              //@ts-ignore
              leadingIcon={otherContent.leadingIcon || ''}
              show={show}
              // @ts-ignore
              onClose={() => setShow(false)}
              // @ts-ignore
              type={otherContent.type}
              actionText={otherContent.actionText ?? ''}
              onAction={() => {
                otherContent.onAction?.()
              }}
              animation={otherContent.animation ?? false}
            />
          </div>
        </SnackbarContainer>
      )}
      {children}
    </SnackBarNotificationContext.Provider>
  )
}

interface ISnackbarContext {
  showNotification: (isShow: boolean, params: ISnackbarContent) => void
}

/**
 * I think this could be improved. These could be the params of the UIKIT component. We could
 * have a global TS for the Snackbar.
 * Now it seems a mix of params, even some params for the Styled Component, which looks like
 * a patch, and we should not make patch when consuming UIKIT
 **/
interface ISnackbarContent {
  message?: string
  autohide?: boolean
  leadingIcon?: string
  className?: string
  position?: string
  style?: object
  type?: string
  onAction?(...args: unknown[]): unknown
  onClose?: (() => void) | undefined
  actionText?: string
  animation?: boolean
}

export default SnackBarNotificationProvider

export { SnackBarNotificationContext }
