import React, { memo } from 'react'
import { object, string, bool } from 'prop-types'
import styled from 'styled-components'

import { px2rem, mq, hiDPIpx } from '@utils'
import { Popover } from '@mch-group/uikit-components'
import CardSelectionState from '@components/collections/CardSelectionState'
import { Icon } from '@mch-group/uikit-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'

const CardVisibleState = memo(
  // @ts-ignore
  ({ entityId, isOrganizeMode, notAvailableLabel, entityType, tipMessage, className, organizeIcon, hasEye = true }) => (
    <div>
      <StyledMoreInfo>
        <NotAvailableLabel className='label label-2 color-darkgrey'>{notAvailableLabel}</NotAvailableLabel>
        <WrapperDiv>
          <Popover content={tipMessage} placement='top' alignment='start' buttonSize='xs' />
        </WrapperDiv>
      </StyledMoreInfo>
      {hasEye ? (
        //the classname can be used to style the closed eye for different views
        <StyledCloseEye className={className}>
          <Circle>
            <Icon name='eye-hide' color='var(--bs-mch-gray-400)' />
          </Circle>
        </StyledCloseEye>
      ) : null}
      {isOrganizeMode && <CardSelectionState entityId={entityId} entityType={entityType} className={organizeIcon} />}
    </div>
  )
)
CardVisibleState.displayName = 'CardVisibleState'

const NotAvailableLabel = styled.div`
  margin: ${px2rem(-3)} ${px2rem(10)} 0 0;
  ${mq.between('tablet', 'largestTablet')`
    font-size: ${hiDPIpx(14)} !important;
    line-height: ${hiDPIpx(16)} !important;
    margin: 0 ${hiDPIpx(14)} 0 0;
  `}

  ${mq.lessThan('largestMobile')`
    font-size: ${tokens.fontSize[1].value} !important;
    line-height: ${tokensRenamed.lineHeights[0].value} !important;
    margin: 0 ${px2rem(14)} 0 0;
  `}
`
const WrapperDiv = styled.div`
  ${mq.lessThan('largestMobile')`
    top: ${px2rem(26)};

    svg {
      width: ${px2rem(16)};
      height: ${px2rem(16)};
    }
  `}
  svg {
    opacity: 1;
  }
`

const Circle = styled.div`
  border: 1.5px solid var(--bs-mch-galleries);
  width: ${px2rem(75)};
  height: ${px2rem(75)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  ${mq.lessThan('largestTablet')`
    transform: scale(0.6);
  `}
`

const StyledMoreInfo = styled.div`
  padding: ${px2rem(20)};
  background: var(--bs-mch-galleries);
  display: flex;
  justify-content: flex-end;
  top: 0;
  width: 100%;
  z-index: 1;
  text-align: right;
  align-items: center;
  ${mq.between('tablet', 'largestTablet')`
    font-size: ${hiDPIpx(14)};
    line-height: ${hiDPIpx(16)};
    padding: ${hiDPIpx(18)};
    margin: 0;
  `}

  ${mq.lessThan('largestMobile')`
    font-size: ${tokens.fontSize[1].value};
    line-height: ${tokensRenamed.lineHeights[0].value};
    padding: ${px2rem(18)};
    margin: 0;
  `}
`

const StyledCloseEye = styled.div`
  background: var(--bs-mch-galleries);
  display: flex;
  justify-content: center;
  padding-top: ${px2rem(60)};
  padding-bottom: ${px2rem(60)};
  ${mq.between('tablet', 'largestTablet')`
    padding-top: ${hiDPIpx(25)};
    padding-bottom: ${hiDPIpx(25)};
  `}

  ${mq.lessThan('largestMobile')`
    padding-top: ${px2rem(25)};
    padding-bottom: ${px2rem(25)};
  `}
`

// @ts-ignore
CardVisibleState.propTypes = {
  entityId: string,
  summary: object,
  isOrganizeMode: bool,
  notAvailableLabel: string,
  entityType: string,
  hasEye: bool,
  className: string,
  tipMessage: string,
  organizeIcon: string
}

export default CardVisibleState
