import { FormikConsumer } from 'formik'
import { Col, Button, Text } from '@mch-group/uikit-components'
import { PasswordValidationCheck } from '@components'
import { useUserAccessContext } from '../context/useUserAccessContext'


const ResetPasswordStep = () => {
  const { state: { apiErrorMessage, isLoading, labels: userAccessLabels }, dispatch } = useUserAccessContext()
  return (
    <FormikConsumer>
      {({ handleBlur, values, errors, touched, handleChange }) => (
        <Col className='mt-0'>
          <h4 className='pb-7'>{userAccessLabels.ResetPassword.EnterNewPasswordLabel}</h4>
          <div className='pb-7'>
            <Text
              label={userAccessLabels.ResetPassword.NewPasswordLabel}
              type='password'
              value={values.newPassword}
              onChange={(e) => {
                    handleChange(e)
                    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                  }}
              onBlur={handleBlur}
              id='newPassword'
              helpText={(touched.newPassword && errors.newPassword) ? errors.newPassword as string : apiErrorMessage}
              helpTextType={(errors.newPassword && touched.newPassword) || apiErrorMessage ? 'danger' : 'muted'}
            />
          </div>
          <Text
            label={userAccessLabels.ResetPassword.ReEnterNewPasswordLabel}
            type='password'
            value={values.newPasswordConfirm}
            onChange={(e) => {
                  handleChange(e)
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                }}
            onBlur={handleBlur}
            id='newPasswordConfirm'
            helpText={(touched.newPasswordConfirm && errors.newPasswordConfirm)
              ? errors.newPasswordConfirm as string : apiErrorMessage}
            helpTextType={(errors.newPasswordConfirm && touched.newPasswordConfirm) || apiErrorMessage ? 'danger' : 'muted'}
          />
          <PasswordValidationCheck
            passwordHeadingLabel={userAccessLabels.Access.passwordValidationHeaderLabel}
            passwordString={values.newPassword}
            minCharLabel={userAccessLabels.Access.validateLengthFieldLabel}
            upperCaseLabel={userAccessLabels.Access.validateUpperCaseFieldLabel}
            numericalCharLabel={userAccessLabels.Access.validateNumericFieldLabel}
            className='mt-7'
            isPasswordValidated={!!(touched.newPassword && errors.newPassword)}
          />
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
              isLoading={isLoading}
            >
              Save
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default ResetPasswordStep
