import React from 'react'
import { string, object, func, bool, array } from 'prop-types'
// import { Modal as StyledModal } from '@components'
import styled from 'styled-components'
// import classNames from 'classnames'
// import SelectorTrigger from '@components/SelectorTrigger'
// import SelectorControls from '@components/SelectorControls'
// import axios from 'axios'
// import { logger, useDebounce } from '@dmi-mch/utils'
// import { Icon } from '@mch-group/uikit-components'

import {
  px2rem,
  mq
  // StyleModalFullscreen
} from '@utils'
// import update from 'immutability-helper'
// import IntersectionCapture from '@reusable-modules/IntersectionCapture'
// import LayoutListItem from './Layouts/ListItem'
// import LayoutGridItem from './Layouts/GridItem'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const mID = 'super-selector'

// const CancelToken = axios.CancelToken

/**
 * This is a Component that allows any list of items to have:
 * Infinite scroll
 * 2 grid views
 * Search
 * Toggling of items
 * Popup behavior
 */
const SuperSelectorForm = () => <div>SuperSelector UIKIT Migration</div>
SuperSelectorForm.displayName = 'SuperSelectorForm'



const SuperSelectorStyled = styled(SuperSelectorForm)`
  &.${mID} {
    span {
      &[role='button'] {
        cursor: pointer;
      }
    }

    &.ui.modal {
      .filters-row {
        margin: -16px -16px 0 -16px;
      }

      ${mq.greaterThan('desktop')`
        width: ${px2rem(683)}
      `};

      > .header {
        ${mq.lessThan('largestTablet')`
          padding-right: ${px2rem(16)} !important;
        `}

        .title {
          display: flex;
          justify-content: space-between;

          .label-1 {
            font-weight: inherit;
          }

          svg {
            margin-top: ${px2rem(7)};
          }

          ${mq.greaterThan('desktop')`
            display: none;
          `};
        }
      }

      .scrolling.content {
        flex-grow: 1;
        padding-top: 0;

        .ui.grid.column {
          margin: -${px2rem(16)} -${px2rem(12)};

          .column {
            padding-left: ${px2rem(12)};
            padding-right: ${px2rem(12)};

            &.grid-view {
              margin-top: ${px2rem(10)};
              margin-bottom: ${px2rem(10)};
            }

            &.no-results {
              margin-bottom: ${px2rem(50)};
            }
          }
        }
      }

      .actions {
        border-top: 1px solid var(--bs-mch-galleries) !important;
      }

      .grid-selector {
        position: relative;
        top: ${px2rem(10)};

        button.ui.button {
          padding: 0;
          background: none;
          border: none;

          &:not(.active) {
            opacity: 0.5;
          }

          & + button.ui.button {
            margin-left: ${px2rem(1)};
          }
        }
      }

      .input-search {
        /* Magnifying glass icon */
        svg {
          left: 0;
          position: absolute;
          top: ${px2rem(10)};
        }

        /* Reset text button and X button */
        & + .controls {
          position: absolute;
          right: 0;
          top: ${px2rem(6)};
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          > span {
            cursor: pointer;
            margin-right: ${px2rem(10)};
            color: var(--bs-mch-gray-400);
            text-decoration: underline;

            &::after {
              content: "|";
              margin-left: ${px2rem(10)};
              color: ${tokens.color.light.base.neutrals['500'].value};
              font-size: ${tokens.fontSize[4].value};
              ${mq.lessThan('largestTablet')`
                display: none;
              `}
            }
          }

          svg {
            cursor: pointer;
            top: ${px2rem(4)};
            position: relative;
            ${mq.lessThan('largestTablet')`
              display: none;
            `}
          }
        }

        input {
          padding-bottom: ${px2rem(22)};
        }
      }

      .first-letter {
        margin-bottom: ${px2rem(15)};
      }

      .column + .first-letter {
        margin-top: ${px2rem(15)};
      }

      span[role='button'] {
        cursor: pointer;
      }
    }
  }
`

SuperSelectorForm.propTypes = {
  className: string,
  title: string,
  value: array,
  service: func,
  serviceDefaultOptions: func,
  labels: object,
  onChange: func,
  onClickOkButton: func,
  disabled: bool,
  extraParams: object,
  searchTextPropertyName: string,
  imageFieldName: string,
  letterFieldName: string,
  placeholderText: string,
  isFilterSet: bool,
  id: string
}

export default SuperSelectorStyled
