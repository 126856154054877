import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

const useEngagementTracker = () => {
  const hasEngaged = useRef<boolean>(false)
  const pathname = usePathname()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    // reset engagement tracking when pathname changes
    hasEngaged.current = false

    const handleClick = () => {
      if (timeoutRef.current && !hasEngaged.current) {
        hasEngaged.current = true // prevent multiple triggers

        // DV360, GAds and FB event
        if (typeof gtag !== 'undefined' && typeof fbq !== 'undefined') {
          // send GA4 event for debugging
          dataLayer.push({
            event: 'user_engagement_10s'
          })
          gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: 'DC-15156044/allca0/allca00+unique'
          })
          gtag('event', 'conversion', { send_to: 'AW-16772450694/0SFICN6_0pgaEIaT3b0-' })
          fbq('track', 'Hot Audience')
        }
      }
    }

    // 10-second timer
    timeoutRef.current = setTimeout(() => {
      window.addEventListener('click', handleClick)
    }, 10000)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      window.removeEventListener('click', handleClick)
    }
  }, [pathname])

  return null
}

export default useEngagementTracker
