import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@mch-group/uikit-components'
import Router from 'next/router'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { mq, px2rem, getRouterQueryParam } from '@utils'
import { TabsData } from '@modules/collections/constants'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const TabsButtons = ({ collectionDetail, editPreviewCollectionItems, mutateCollectionDetail }) => {
  //@ts-ignore
  const [{ router, activeTab, isOrganizeMode, labels }, dispatch] = useMyCollectionsPageContext()
  const btnProps = (key) => ({
    className: 'label label-2 bg-white',
    opacity: 1,
    onClick: () => handleTabClick(key),
    active: activeTab === key || (!activeTab && key === 'artworks' ? true : false)
  })

  //create tabs in collection detail page based on contentsCount key in the api response
  const TabKey = Object.keys(collectionDetail.contentsCount)
  const EventsAndExhibitionsCount =
    collectionDetail.contentsCount['4a'] > 0 ? true : collectionDetail.contentsCount['4b'] > 0
  if (EventsAndExhibitionsCount === true) {
    TabKey.push('4a,4b')
  }
  const CollectionsTabs = TabsData.filter((item) => TabKey.indexOf(item.entityType) > -1)

  const handleTabClick = React.useCallback(
    (tab) => {
      const activeTabData = CollectionsTabs.filter((data) => data.id === tab)
      const activeTabText = activeTabData && activeTabData[0]?.id

      // avoids double clicks and repeated requests
      if (tab !== activeTab && CollectionsTabs.length > 0) {
        // set activeTab and writes in the url
        dispatch({
          type: 'SET_ACTIVE_TAB',
          payload: activeTabText ? activeTabText : null
        })
        dispatch({
          type: 'SET_ACTIVE_TAB_ENTITY_TYPE',
          payload: activeTabData ? activeTabData[0]?.entityType : null
        })
        let newUrl
        if (tab) {
          newUrl = `${window.location.pathname}?id=${getRouterQueryParam(router, 'id')}&activeTab=${tab}`
        } else {
          newUrl = `${window.location.pathname}?id=${getRouterQueryParam(router, 'id')}`
        }
        Router.push(
          {
            pathname: '/',
            query: tab
              ? {
                  activeTab: tab,
                  id: getRouterQueryParam(router, 'id')
                }
              : {}
          },
          newUrl,
          { shallow: false }
        )
      }
    },
    [CollectionsTabs, activeTab, dispatch, router]
  )

  React.useEffect(() => {
    if (editPreviewCollectionItems?.length === 0 && activeTab && isOrganizeMode) {
      dispatch({ type: 'SET_ORGANIZE_MODE', payload: !isOrganizeMode })
      {
        /***These steps are to delete the tab. If the active tab content
      no items we need to remove that tab. Tab creation and deletion
      will be completly dynamic based on the api response. ***/
      }

      const activeTabArr = TabsData.filter((item) => item.id === activeTab)
      let activeTabEntityObj = activeTabArr[0].entityType
      const updatedContentCount = collectionDetail?.contentsCount
      if (activeTabEntityObj === '4a,4b') {
        delete updatedContentCount['4a']
        delete updatedContentCount['4b']
      } else {
        delete updatedContentCount[activeTabEntityObj]
      }
      //Here we are updating the contentsCount in collectionDetail
      mutateCollectionDetail({
        ...collectionDetail,
        contentsCount: updatedContentCount
      })
      //Here we are updating the tab key based on latest contentCount
      const UpdatedTabKey = Object.keys(updatedContentCount)
      const UpdatedCollectionsTabs = TabsData.filter((item) => UpdatedTabKey.indexOf(item.entityType) > -1)
      const activeTabText = UpdatedCollectionsTabs && UpdatedCollectionsTabs[0]?.id
      handleTabClick(activeTabText ? activeTabText : null)
    }
  }, [
    CollectionsTabs,
    activeTab,
    collectionDetail,
    dispatch,
    editPreviewCollectionItems,
    handleTabClick,
    isOrganizeMode,
    mutateCollectionDetail
  ])

  React.useEffect(() => {
    {
      /* this approach will avoid adding the static tab key like 'artworks' in the url.We will find the active tab based
    on the tabs that we wanted to display. **/
    }
    if (!activeTab) {
      const urlParams = new URLSearchParams(window.location.search)
      const defaultActiveTab = urlParams.get('activeTab')
      const activeTabText = CollectionsTabs && (defaultActiveTab || CollectionsTabs[0]?.id)
      handleTabClick(activeTabText)
    }
  }, [CollectionsTabs, activeTab, dispatch, handleTabClick, router])

  return (
    <TabsWrapper>
      <TabsContainer>
        {CollectionsTabs.map((data) => (
          <FilterButton key={data.id} {...btnProps(data.id)} disabled={isOrganizeMode}>
            {labels?.Collections[data.name] || data.name} (
            {data.entityType === '4a,4b'
              ? (collectionDetail?.contentsCount['4b'] || 0) + (collectionDetail?.contentsCount['4a'] || 0)
              : collectionDetail.contentsCount[data.entityType]}
            )
          </FilterButton>
        ))}
      </TabsContainer>
    </TabsWrapper>
  )
}

const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${px2rem(50)};
  ${mq.lessThan('desktop')`
    width: 100vw;
    margin-left: -17.7778px !important;
  `}

  ${mq.lessThan('largestTablet')`
    margin-bottom: ${px2rem(40)};
  `}
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  max-width: 100%;
  ${mq.lessThan('desktop')`
    padding-left: 17.7778px;
    padding-bottom: 5px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ${mq.lessThan('largestTablet')`
     justify-content: flex-start;
     align-items: flex-start;
  `}
`

const FilterButton = styled(Button)`
  border-radius: ${px2rem(45)};
  background: ${tokens.color.base.primary.white.value};
  text-align: center;
  padding: ${px2rem(8)} ${px2rem(30)};
  margin-right: ${px2rem(10)};
  border: 1.5px solid ${({ active }) => (active ? 'var(--bs-mch-gray-500)' : 'var(--bs-mch-galleries)')};
  transition: all 0.25s ease;
  color: var(--bs-mch-gray-500) !important;
  opacity: ${({ opacity }: any) => (opacity ? 1 : 0)};
  font-weight: ${tokens.fontWeights.artBaselText1.value};
  font-size: ${tokens.fontSize[1].value};
  height: unset !important;
  text-transform: capitalize;

  &.active {
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
  }

  &:hover {
    background-color: ${({ active }) =>
      active ? 'var(--bs-mch-galleries)' : tokens.color.base.primary.white.value} !important;
    height: unset !important;
    max-height: unset !important;
    border: 1.5px solid var(--bs-mch-gray-400) !important;
    color: inherit !important;
  }

  ${mq.lessThan('largestTablet')`
     padding: ${px2rem(8)} ${px2rem(15)};
  `}
`

TabsButtons.propTypes = {
  collectionDetail: PropTypes.object,
  editPreviewCollectionItems: PropTypes.array,
  mutateCollectionDetail: PropTypes.func
}

export default TabsButtons
