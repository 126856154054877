import { useCallback, useContext, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import NProgress from 'nprogress'
import dynamic from 'next/dynamic'
import Spacings from '@components/Spacings'
import Colors from '@components/Colors'
import { isAndroidWebview, isiOSWebview, findUrlContext, logPageView, getAgentKey } from '@utils'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { AppContext } from '@providers/AppContext'
import { PageContext } from '@providers/PageContext'
import useOnWebsiteRefresh from '@hooks/useOnWebsiteRefresh'
import useScrollEventTracker from '@hooks/useScrollEventTracker'
import usePageViewTracker from '@hooks/usePageViewTracker'
import useEngagementTracker from '@hooks/useEngagementTracker'
import Cookies from 'js-cookie'

const useLayout = () => {
  const {
    state: { siteHostName, isMobileWebview }
  } = useContext(AppContext)
  const { page } = useContext(PageContext)
  const router = useRouter()

  // This is a custom hook that checks if the page is being refreshed
  useOnWebsiteRefresh()
  // google tag manager scroll event
  useScrollEventTracker()
  // google tag manager page view event
  usePageViewTracker()
  // google tag manager engagement event
  useEngagementTracker()

  // Extracting lots of props out Redux
  const reduxState = useSelector((state) => state)
  const pathname = page.pageData?.shareUrl
  const {
    user,
    page: { isFirstModuleVideoPlayer, pageHasMenu, userAgent }
  } = reduxState

  // Extracting info NOT from the redux object
  const onlyForLargeScreens = page.pageCmsData?.onlyForLargeScreens
  const layout = page.pageCmsData?.layout
  const hideFooter = page.pageCmsData?.hideFooter || !!router.query.hideFooter || !!router.query.floorplan
  const hideHeader = page.pageCmsData?.hideHeader
  const hostname = siteHostName?.hostname
  /**
   * Being this a general layout,
   * it is not very nice it deals with such particular 'onlineShow' stuff.
   * But we need this.
   */
  const { onlineShowId, showInRoom } = page.pageData
  const { siteLanguage } = page
  const SmartBanner = dynamic({
    loader: () => import(/* webpackChunkName: 'SmartBannerImpl' */ '../SmartBannerImpl'),
    ssr: false,
    loading: () => null
  })
  const isCatalogDetail = findUrlContext(pathname, 'isCatalogDetail')
  const fromWebView = isAndroidWebview(userAgent) || isiOSWebview(userAgent) || isMobileWebview

  const initialClientWidth = useRef<number | null>(null)
  const hashtimeoutHandle = useRef<number | undefined>(undefined)

  const onOrientationChange = () => {
    initialClientWidth.current = document.body.clientWidth
  }

  const handleRouteChangeStart = useCallback(() => {
    NProgress.start()
  }, [])

  const handleRouteChangeComplete = useCallback(() => {
    NProgress.done()
  }, [])

  const hasTrackedInitialPageView = useRef(false)
  useEffect(() => {
    const handleRouteChange = () => {
      logPageView(user.me, siteLanguage, user?.accountType)
    }
    if (!user.meIsLoading && !user.profileDataLoading && !hasTrackedInitialPageView.current) {
      handleRouteChange()
      hasTrackedInitialPageView.current = true
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, siteLanguage, user])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', NProgress.done)
  }, [handleRouteChangeComplete, handleRouteChangeStart, router.events])

  useEffect(() => {
    const triggerOnLoad = async () => {
      initialClientWidth.current = document.body.clientWidth
      window.addEventListener('orientationchange', debounce(onOrientationChange, 250))
      window.addEventListener(
        'resize',
        debounce(() => {}, 500)
      )
    }
    triggerOnLoad()
    return () => {
      window.removeEventListener('orientationchange', debounce(onOrientationChange, 250))
      window.removeEventListener(
        'resize',
        debounce(() => {}, 500)
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(hashtimeoutHandle.current)
    }
  }, [])

  // Checking if OneTrust is ready and making it listen on every cookie change
  try {
    window.addEventListener('OptanonReady', () => {
      Optanon.OnConsentChanged(async () => {
        // C0004 stands for 'Targeting Cookies'
        const isTargetingCookiesTypeAccepted = OnetrustActiveGroups.indexOf('C0004') > -1
        if (isTargetingCookiesTypeAccepted) {
          getAgentKey()
        } else {
          Cookies.remove('agentKey')
        }
      })
    })
  } catch (err) {}

  return {
    siteLanguage,
    fromWebView,
    showInRoom,
    onlineShowId,
    hideHeader,
    isFirstModuleVideoPlayer,
    isCatalogDetail,
    pageHasMenu,
    onlyForLargeScreens,
    hideFooter,
    layout,
    SmartBanner,
    hostname,
    ColorsStyledComponent: Colors,
    SpacingsStyledComponent: Spacings
  }
}

export default useLayout
