import styled, { createGlobalStyle } from 'styled-components'

const ModalCustom = () => (
  <>
    <Transition />
    Mobile UIKIT MIgration
    {/* <Modal {...props} /> */}
  </>
)

const Transition = createGlobalStyle`
  @keyframes opacityTransition {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .ui.dimmer {
    animation: opacityTransition 420ms;
  }
`

const StyledModalCustom = styled(ModalCustom).withConfig({ componentId: 'dmi-ui-modal' })`
  @keyframes slideInTransition {
    0% { top:-80px; opacity: 0; }
    100% { top:0px; opacity: 1; }
  }

  &.ui.modal {
    top:0;
    animation: slideInTransition 420ms;

    &.in-transition {
      transition: all 420ms;
      top: -80px;
      opacity: 0;
    }
  }
`

ModalCustom.propTypes = {}

export default StyledModalCustom
