import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Loader, VirtualGrid } from '@components'

import { px2rem } from '@utils'
import CardVisibleState from '@components/collections/CardVisibleState'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import TabContentHeader from '@components/collections/edit-preview-collection/Tabs/TabContentHeader'
import GalleryItem from '@modules/Catalog/Galleries/GalleryItem'

const GalleriesTab = (props) => {
  const {
    editPreviewCollectionItems,
    totalItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    isLoadingMore,
    size,
    isTabExist
  } = props

  //@ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { onSelectCard } = useCollectionUtils()
  const isIntersectionActive =
    editPreviewCollectionItems &&
    !editPreviewCollectionItemsValidating &&
    !isRefreshing &&
    !isEmpty &&
    !isLoadingInitialData &&
    !isLoadingMore

  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels?.Collections.galleriesSubHeadingLabel}
        isTabExist={isTabExist}
      />
      {editPreviewCollectionItems.length > 0 ? (
        <>
          <VirtualGrid
            //@ts-ignore
            items={editPreviewCollectionItems}
            stopRedraw={isOrganizeMode || !isIntersectionActive}
            renderItem={(event) => {
              const hasGalleryNotAvailable = Object.values(event.status).some((item) => item === true)
              return !hasGalleryNotAvailable ? (
                <GalleryItem
                  {...event.item}
                  isOrganizeMode={isOrganizeMode}
                  showShowsData={false}
                  onSelectCard={onSelectCard}
                  labels={{
                    imageAlt: labels?.AltTags?.generalViewGallery,
                    addBtn: labels?.AltTags?.addToCollection,
                    ariaLabel: labels?.AltTags?.generalReadMoreAbout
                  }}
                  isSelectedInCollections={
                    typeof selectedItems?.find(
                      (x) => x.entityId?.toString() === (event?.id?.toString() || event?.entityId?.toString())
                    ) !== 'undefined'
                  }
                />
              ) : (
                <CardVisibleState
                  //@ts-ignore
                  isOrganizeMode={isOrganizeMode}
                  notAvailableLabel={labels?.Collections.notAvailableForViewLabel}
                  entityType='1'
                  tipMessage={labels?.Collections.unavailableGalleriesHelperText}
                  className='not-visible-card'
                  organizeIcon='organize-icon'
                />
              )
            }}
          />
          <IntersectionCapture
            onIntersect={() => setSize(size + 1)}
            active={isIntersectionActive}
            params={{ rootMargin: '400px' }}
          />
        </>
      ) : null}
      {(isLoadingInitialData || isLoadingMore) && (
        <LoaderContainer>
          {/* @ts-ignore */}
          <Loader centered active />
        </LoaderContainer>
      )}
    </div>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${px2rem(100)};
`

GalleriesTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isOrganizeMode: PropTypes.bool,
  totalItems: PropTypes.number,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isTabExist: PropTypes.bool
}
export default GalleriesTab
