import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { Spinner } from '@mch-group/uikit-components'
import { convertHexToRgba } from '@utils/cssHelpers'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FullScreenLoader = ({ ...props }) => {
  const { className, active = false } = props
  if (!active) {
    return null
  }
  return (
    <StyledFullScreenLoader className={className}>
      <Spinner variant='button' theme='light' />
    </StyledFullScreenLoader>
  )
}

FullScreenLoader.propTypes = {
  props: PropTypes.object,
  className: PropTypes.string,
  active: PropTypes.bool
}

const StyledFullScreenLoader = styled.div`
  display: flex !important;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[1000].value, tokens.opacity[30].value)});
`

export default FullScreenLoader
