import React from 'react'
import { ConfirmContext } from '@providers/Confirm'
import { Collections } from '@services'
import { useCollectionsContext } from '@modules/collections/contexts/useCollectionsContext'
import useMyCollectionsData from '@modules/collections/hooks/useMyCollectionsData'
import { SnackBarNotificationContext } from '@providers/SnackBarNotification'

const useRemoveCollection = () => {
  const { showConfirm, setVisible, setButtonStates } = React.useContext(ConfirmContext)
  const { showNotification } = React.useContext(SnackBarNotificationContext)

  const { mutateMyCollections, setActive } = useMyCollectionsData()
  const [{ labels, list: myCollectionList }, dispatch] = useCollectionsContext()

  const remove = async (id, onDeleteCallback = () => {}) => {
    setButtonStates({
      disabled: true,
      isLoading: true
    })
    try {
      const response = await Collections.delete(id)
      if (response.ok) {
        const updatedList =
          myCollectionList.length &&
          myCollectionList[0]?.items?.length &&
          myCollectionList[0].items.filter((ele) => ele.id !== id)
        myCollectionList[0].items = updatedList
        setActive(true)
        mutateMyCollections((list) => {
          const newList = [...list]
          if (newList && newList.length > 0) {
            newList.forEach((page, index) => {
              const item = page.items.findIndex((i) => i.id === id)
              if (item >= 0) {
                newList[index].items.splice(item, 1)
              }
            })
          }
          return newList
        }, false)
        onDeleteCallback()
        setVisible(false)
        dispatch({ type: 'SET_LIST', payload: myCollectionList })
        showNotification(true, {
          autohide: true,
          position: 'top-end',
          message: labels.Collections.successfulDeleteCollection,
          className: 'position-fixed',
          type: 'default'
        })
      }
    } catch (e) {
      setVisible(true)
    }
  }

  function removeCollection(id, name, subText, onDeleteCallback = () => {}) {
    showConfirm({
      labels: {
        title: `${labels.Collections.deleteCollectionPopUp}`,
        subText,
        message: `${labels.Collections.deleteAlertMessageLabel} ${name}?`,
        ok: labels.Collections.deleteButtonLabel,
        cancel: labels.Collections.cancelButtonLabel
      },
      actions: {
        onOK: () => {
          remove(id, onDeleteCallback)
        },
        onCancel: () => {
          setActive(false)
          setVisible(false)
        }
      }
    })
  }

  return {
    removeCollection
  }
}

export default useRemoveCollection
