import PropTypes from 'prop-types'
import styled from 'styled-components'
import { px2rem, hiDPIpx, mq } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const ProgressBarContent = ({ className, size, percent, indicating, attached, colorcodes }) => (
  <div
    status={status}
    className={className}
    size={size}
    percent={percent}
    indicating={indicating}
    attached={attached}
    colorcodes={colorcodes}
  />
)

ProgressBarContent.propTypes = {
  size: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  indicating: PropTypes.bool,
  attached: PropTypes.string,
  className: PropTypes.string,
  colorcodes: PropTypes.string
}

export const ProgressBar = styled(ProgressBarContent)`
  height: ${px2rem(5)} !important;
  border-radius: 0 !important;
  background: var(--bs-mch-gray-100) !important;
  ${mq.between('tablet', 'largestTablet')`
    height: ${hiDPIpx(5)} !important;
  `}

  .bar {
    background: ${(props) => `${props.colorcodes || 'var(--bs-mch-gray-100)'}`} !important;
    border-radius: 0 !important;
    min-width: 0 !important;

    &::after {
      background: ${tokens.color.base.primary.white.value} !important;
    }
  }
`