import PropTypes from 'prop-types'
import styled from 'styled-components'
import { px2rem } from '@utils'

const OrganizeTitle = ({ organizeTitle }) => (
  <StyledOrganizeTitle as='h4' className='text-paragraph-2 color-darkgrey'>
    {organizeTitle}
  </StyledOrganizeTitle>
)

const StyledOrganizeTitle = styled.div`
  margin-bottom: ${px2rem(24)};
`

OrganizeTitle.propTypes = {
  organizeTitle: PropTypes.string
}

export default OrganizeTitle
