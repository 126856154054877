import { FC } from 'react'
import styled from 'styled-components'

import { px2rem } from '@utils'

const Loader: FC<ILoader> = () =>
  <div>Loading...</div>

interface ILoader {
  active: boolean,
  className?: string,
  $noMargin?: boolean
}

export default styled(Loader)`
  &&& {
    ${(props) => !props.$noMargin ? `margin: 0 auto ${px2rem(60)};` : 'margin: 0 auto;'}
  }
`
