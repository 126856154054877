import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

const usePageViewTracker = () => {
  const hasTrackedView = useRef<boolean>(false)
  const hasTrackedClick = useRef<boolean>(false)
  const pathname = usePathname()

  useEffect(() => {
    hasTrackedView.current = false
    hasTrackedClick.current = false

    const timeoutId = setTimeout(() => {
      if (!hasTrackedView.current) {
        hasTrackedView.current = true
        if (typeof gtag !== 'undefined') {
          // Send GA4 event for debugging
          dataLayer.push({
            event: 'user_page_view_10s'
          })
          gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: 'DC-15156044/allca0/allca003+standard'
          })
          gtag('event', 'conversion', { send_to: 'AW-16772450694/NfaPCPaW1ZgaEIaT3b0-' })
        }
      }
    }, 10000) // 10 seconds

    return () => {
      clearTimeout(timeoutId)
    }
  }, [pathname])

  return null
}

export default usePageViewTracker
