import React from 'react'
import { withFormik } from 'formik'
import SelectorControls from '@components/SelectorControls'
import InputFeedback from '@components/Form/InputFeedback'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { px2rem, getSiteLanguage } from '@utils'
import { Collections as collectionsService, Translations as TranslationsService } from '@services'
import { logger } from '@dmi-mch/utils'
import * as Yup from 'yup'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { Container } from '@mch-group/uikit-components'
import StyledCharcounter from '@components/Charcounter'
import CallToAction from '@components/reusable-modules/common/CallToAction'

const CreateEditCollectionForm = (props) => {
  const {
    handleSubmit,
    errors,
    values,
    touched,
    dirty,
    handleChange,
    handleBlur,
    collectionLabels,
    collectionFormLabels,
    setVisible,
    isSubmitting,
    collectionDetail,
    collectionDelete
  } = props

  return (
    <StyledForm>
      <form autoComplete='off'>
        <Container error={errors.name && touched.name} id='form-field' className='collection-field d-flex flex-column'>
          <label htmlFor='collection-name' className='color-black'>
            {collectionLabels.nameLabel}
          </label>
          <CollectionNameInput
            type='text'
            id='collection-name'
            name='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name && touched.name}
            fluid
            className='collection-name'
          />
          {errors.name ? (
            <InputFeedback>{errors.name}</InputFeedback>
          ) : (
            <span className='label label-3 color-textgrey'>{collectionLabels.nameLimitLabel}</span>
          )}
        </Container>
        <Container className='collection-field mt-4 d-flex flex-column'>
          <label htmlFor='description' className='description color-black'>
            {collectionLabels.descriptionLabel}
          </label>
          <textarea
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={collectionLabels.hintOnDescription}
            className='collection-description'
            id='description'
            value={values.description}
          ></textarea>
          <StyledCharcounter
            max={500}
            current={values.description.length}
            text={collectionLabels.descriptionCharacterLimit}
          />
        </Container>
        {collectionDetail && (
          <CallToAction
            className='color-red mt-4'
            onClick={async () => {
              let subText = ''
              if (collectionDetail.numFollowers > 0) {
                const subTextLabel = await TranslationsService.getDynamicLabelValue(
                  'Collections',
                  'deleteFollowedCollectionAlertMsgLabel',
                  getSiteLanguage(),
                  collectionDetail.numFollowers
                )
                // @ts-ignore
                subText = subTextLabel.data.value
              }
              setVisible() //close the edit collection popup first
              collectionDelete(collectionDetail.id, name, subText)
            }}
            hideArrow
            bold
            cursor='pointer'
            textDecoration
          >
            {collectionLabels.deleteLabelOnPopUp}
          </CallToAction>
        )}
        <SelectorControlsStyled
          resetBtnActive
          onChange={handleSubmit}
          onClickReset={() => setVisible(false)}
          labels={{
            reset: collectionLabels.cancelButtonLabel,
            apply: collectionDetail ? collectionFormLabels.saveButtonLabel : collectionLabels.createButtonLabel
          }}
          buttonType='submit'
          isLoading={isSubmitting}
          disabled={isSubmitting || !dirty || Object.keys(errors).length !== 0}
        />
      </form>
    </StyledForm>
  )
}

const StyledForm = styled.div`
  label {
    margin-bottom: ${px2rem(10)} !important;
    font-weight: bold;
  }

  label.description {
    margin-bottom: ${px2rem(10)} !important;
  }

  .label.label-3 {
    font-family: inherit;
  }

  .collection-description {
    margin-bottom: 0 !important;
    margin-top: ${px2rem(10)};
    font-family: inherit;
    resize: none;
    height: ${px2rem(120)};
    padding-left: ${px2rem(8)};
    padding-top: ${px2rem(8)};
    font-weight: ${tokens.fontWeights.artBaselText1.value};
    color: var(--bs-mch-gray-400);
  }
`

const CollectionNameInput = styled.input<any>`
  border: 0;
  border-bottom: 1px solid ${tokens.color.light.base.neutrals['500'].value} !important;
  padding: 8px 0 8px;
`
const SelectorControlsStyled = styled(SelectorControls)`
  border-top: 1px solid var(--bs-mch-galleries);
  padding-top: 20px;
  margin-top: 20px;
`

CreateEditCollectionForm.propTypes = {
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  collectionLabels: PropTypes.object,
  setVisible: PropTypes.func,
  isSubmitting: PropTypes.bool,
  collectionFormLabels: PropTypes.object,
  collectionDetail: PropTypes.object,
  collectionDelete: PropTypes.func,
  dirty: PropTypes.bool
}

export default withFormik({
  // @ts-ignore
  mapPropsToValues: ({ collectionDetail }) => ({
    name: collectionDetail?.name || '',
    description: collectionDetail?.description || ''
  }),
  displayName: 'myDetails',
  validateOnChange: true,
  validationSchema: (props) =>
    Yup.object().shape({
      name: Yup.string().max(30, props.collectionLabels.nameErrorMessage).required('This field is required').trim(),
      description: Yup.string().max(500, props.collectionLabels.descriptionCharacterLimit)
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    // @ts-ignore
    const collectionId = props.collectionDetail?.id
    setSubmitting(true)
    const reqParams = {
      properties: [
        {
          name: 'name',
          value: values.name
        },
        {
          name: 'description',
          value: values.description
        }
      ]
    }
    try {
      const isCreating = !collectionId
      const response = isCreating
        ? await collectionsService.create('MANUAL', reqParams)
        : await collectionsService.edit(collectionId, reqParams)
      if (response.ok) {
        // @ts-ignore
        props.submitFormCallback(response.data, values, isCreating, collectionId)
      } else {
        // @ts-ignore
        props.setVisible()
      }
    } catch (e) {
      logger(e)
    }
    setSubmitting(false)
  }
  // @ts-ignore
})(CreateEditCollectionForm)
