import styled from 'styled-components'

import { px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

// eslint-disable-next-line no-unused-vars
const StyledBorderedText = styled(({ children, ...props }) =>
  <div {...props} basic>{children}</div>)
  .withConfig({ componentId: 'dmi-ui-label' })`
  &&.ui {
    text-transform: uppercase;
    border: ${px2rem(2)} solid ${props => props.textColor};
    color: ${props => props.textColor};
    font-size: ${tokens.fontSize[1].value};
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    border-radius: 0;
    padding: ${px2rem(11)} ${px2rem(20)};
    &:not(:last-child) {
      margin-right: ${props => (props.withRightMargin ? px2rem(20) : '0')};
    }
  }
`

export default StyledBorderedText
