import PropTypes from 'prop-types'
import { SolidTriangle } from '@dmi-mch/icons'

import GDBlockWrapper from './Block.styles'
import { Container, Row, Col, Button, Dropdown, TextLink } from '@mch-group/uikit-components'

// block
const GDBlock = ({ children, ...props }) => (
  <GDBlockWrapper className='gd-block' {...props}>
    <Container className='px-5 px-lg-8 px-md-7' style={{ position: 'relative' }}>{children}</Container>
  </GDBlockWrapper>
)

// block header
GDBlock.Header = (
  { children, controls = [], tabButton = [], rowsPerPage = {}, secondRow = null
  }) => {
  const hasTabs = tabButton && tabButton.length > 0
  return (
    <>
      <div className='gd-block-header'>
        <Row className={`${secondRow ? 'first-row' : ''}`}>
          {hasTabs ? (
            <>
              <Col md={9} className={hasTabs ? 'with-tabs' : ''}>
                <h3>{children}</h3>
                {tabButton && tabButton.length > 0 && (
                  <div className='tab-button text-end'>
                    {tabButton.map(i => (
                      <Button
                        className='me-2'
                        size='compact'
                        onClick={() => i.onClickTab()}
                        content={i.text}
                        key={i.id}
                        primary={i.active}
                      />
                    ))}
                  </div>
                )}
              </Col>
              <Col md={3}>
                {rowsPerPage.options && rowsPerPage.options.length > 0 && (
                  <div className='rows-per-page'>
                    <SolidTriangle />
                    <Dropdown
                      label={rowsPerPage.label}
                      closeOnChange
                      upward={false}
                      fluid
                      options={rowsPerPage.options}
                      onChange={(e, obj) => {
                        rowsPerPage.onClick(obj)
                      }}
                      selectOnBlur={false}
                      multiple={false}
                      disabled={false}
                      clearable={false}
                      value={rowsPerPage.valueSelected}
                    />
                  </div>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col md={controls && controls.length > 0 ? 5 : 12}>
                <h3>{children}</h3>
              </Col>
              {controls && controls.length > 0 &&
                <Col md={7}>
                  {controls && controls.length > 0 && (
                    <div className='controls text-end mb-3'>
                      {controls.map(i => (
                        <Button
                          className='me-2'
                          size='compact'
                          key={i.key}
                          type={i.type || null}
                          onClick={i.onClick}
                          disabled={i.isDisabled}
                          primary
                        >{i.text}</Button>
                      ))}
                    </div>
                  )}
                </Col>}
            </>
          )}
        </Row>
        {secondRow}
      </div>
    </>
  )
}

GDBlock.Header.displayName = 'GDBlock.Header'

GDBlock.Header.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.array,
  tabButton: PropTypes.array,
  rowsPerPage: PropTypes.object,
  secondRow: PropTypes.element
}

// block Nav
GDBlock.Nav = ({ children, controls }) => (
  <>
    <div className='gd-block-nav'>
      <Row>
        <Col md={6}>
          <h4>{children}</h4>
        </Col>
        <Col md={6}>
          <div className='text-end'>
            {controls.map(i => (
              <Button
                size='compact'
                className='me-3'
                key={i.key}
                type={i.type || null}
                onClick={i.onClick}
                disabled={i.isDisabled}
                primary={i.primary}
                borderColor={i.borderColor}
                loading={!!i.isLoading}
              >
                {i.text}
              </Button>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  </>
)

GDBlock.Nav.displayName = 'GDBlock.Nav'

GDBlock.Controls = ({ children, backButtonLabel, backButtonAction }) => (
  <div className='gd-block-controls'>
    <div>
      <Row>
        <Col md={3}>
          <TextLink icon='chevron-left' onClick={backButtonAction}>{backButtonLabel}</TextLink>
        </Col>
        <Col md={9}>
          {children}
        </Col>
      </Row>
    </div>
  </div>
)

GDBlock.Controls.displayName = 'GDBlock.Controls'

GDBlock.Title = ({ children, title }) => (
  <div className='gd-block-title'>
    <div>
      <Row>
        <Col md={8}>
          <span className='text-label-xlarge'>{title}</span>
        </Col>
        <Col md={4}>
          {children}
        </Col>
      </Row>
    </div>
  </div>
)

GDBlock.Title.displayName = 'GDBlock.Title'

// block content
GDBlock.Content = ({ children, ...props }) => !props.table ? (
  <Row className={`gd-block-content ${props.className} ${props.clearLast ? 'clear-last-row' : ''}`}>
    <Col>{children}</Col>
  </Row>
) : children

GDBlock.Content.displayName = 'GDBlock.Content'

GDBlock.propTypes = {
  children: PropTypes.node
}

GDBlock.Nav.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.array
}

GDBlock.Controls.propTypes = {
  children: PropTypes.node,
  backButtonLabel: PropTypes.string,
  backButtonAction: PropTypes.func
}

GDBlock.Title.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

GDBlock.Content.propTypes = {
  children: PropTypes.node,
  clearLast: PropTypes.bool,
  table: PropTypes.bool,
  className: PropTypes.string
}

export default GDBlock
