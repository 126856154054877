import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'

const useScrollEventTracker = () => {
  const hasScrolled = useRef<boolean>(false)
  const pathname = usePathname()

  useEffect(() => {
    // Reset scroll tracking when pathname changes
    hasScrolled.current = false

    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight

      // Check if user has scrolled at least 50% of the page
      if (scrollPosition / totalHeight >= 0.5) {
        if (!hasScrolled.current) {
          hasScrolled.current = true // Prevent multiple triggers
          // DV360, GAds and FB event
          if (typeof gtag !== 'undefined' && typeof fbq !== 'undefined') {
            // Send GA4 event for debugging
            dataLayer.push({
              event: 'user_scroll_down_50'
            })
            gtag('event', 'conversion', {
              allow_custom_scripts: true,
              send_to: 'DC-15156044/allca0/allca001+standard'
            })
            gtag('event', 'conversion', { send_to: 'AW-16772450694/fvUWCJP1xJgaEIaT3b0-' })
            fbq('track', 'Scroll Down 50')
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pathname])

  return null
}

export default useScrollEventTracker
