import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import StylesSlickCarousel from './styles'

class SlickCarousel extends React.Component {
  render() {
    const { settings, children, className, setSliderRef } = this.props

    return (
      <div>
        <div className={className}>
          <Slider
            {...settings}
            ref={c => {
              setSliderRef(c)
            }}
          >
            {children}
          </Slider>
        </div>
        <StylesSlickCarousel />
      </div>
    )
  }
}


SlickCarousel.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  setSliderRef: PropTypes.func
}

export default SlickCarousel
