// @ts-nocheck
import Dropzone from 'react-dropzone'
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Modal as UIKitModal } from '@mch-group/uikit-components'
import { api as httpApi } from '@services/_http'
import { validation } from '@constants'

import axios from 'axios'
import styled from 'styled-components'
import shortid from 'shortid'
import * as loadImage from 'blueimp-load-image'
import px2rem from '../utils/px2rem'

class Uploader extends React.Component {
  static propTypes = {
    dropzone: PropTypes.object,
    accessToken: PropTypes.string,
    children: PropTypes.func,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    api: PropTypes.string,
    apiFormData: PropTypes.object,
    apiHeaders: PropTypes.object,
    uploadOnFileSelect: PropTypes.bool,
    uploadToFuturetek: PropTypes.bool,
    uploadButtonLabel: PropTypes.string,
    defaultInputValue: PropTypes.string,
    onFileInputReset: PropTypes.func,
    onFileUploadSuccess: PropTypes.func,
    resetCallback: PropTypes.func,
    tags: PropTypes.string,
    showFileInput: PropTypes.bool,
    accept: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    fileUploaderTheme: PropTypes.bool,
    ariaLabel: PropTypes.string,
    removeFileLabel: PropTypes.string,
    onlyRomanCharsLabel: PropTypes.string
  }

  static defaultProps = {
    minWidth: 0,
    minHeight: 0,
    multiple: false,
    apiFormData: {}
  }

  defaultMessageState = {
    // eslint-disable-line react/sort-comp
    open: false,
    header: '',
    content: ''
  }

  state = {
    file: null,
    ref: null,
    message: { ...this.defaultMessageState },
    uploadedFile: null,
    uploadInProgress: false
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      return true
    }
    if (this.state.ref === null && nextState.ref !== null) {
      return true
    }
    if (this.state.file === null && this.state.ref !== null) {
      return false
    }
    if (this.state.uploadedFile === null && nextState.uploadedFile && nextState.uploadedFile[0].name !== null) {
      return true
    }
    if (this.state.file !== null && nextState.file && this.state.file[0].size === nextState.file[0].size) {
      return false
    }
    return true
  }

  onFileSelect = (accepted, rejected) => {
    const { minWidth, minHeight, maxSize } = this.props

    if (this.state.file !== null) {
      this.resetUploader()
    }
    const regex = validation.CHINESE_CHARACTERS
    const string = accepted.length > 0 && accepted[0].name ? accepted[0].name : ''

    if (regex.test(string)) {
      this.setState({
        message: {
          open: true,
          header: 'Error',
          content: () => (
            <UIKitModal.Body>
              <p className='text-medium'>
                {this.props.onlyRomanCharsLabel || 'Only roman characters are allowed in the file name!'}
              </p>
            </UIKitModal.Body>
          )
        }
      })
      return
    }

    if (accepted.length > 0) {
      // Image dimension check
      if (accepted[0].type.includes('image/') && accepted[0].preview && (minWidth || minHeight)) {
        const img = new Image()
        img.src = accepted[0].preview

        img.addEventListener('load', (loaded) => {
          // browser fallback: Edge handles 'target' not 'path'
          const { naturalWidth, naturalHeight } = loaded.path ? loaded.path[0] : loaded.target
          if (naturalWidth >= minWidth && naturalHeight >= minHeight) {
            const loadImageOptions = { canvas: true }
            loadImage.parseMetaData(accepted[0], (data) => {
              if (data.exif && data.exif.get('Orientation')) {
                loadImageOptions.orientation = data.exif.get('Orientation')
              }
              loadImage(
                accepted[0],
                (canvas) => {
                  accepted[0].preview = canvas.toDataURL(accepted[0].type) // eslint-disable-line no-param-reassign
                  this.setState({ file: accepted })
                },
                loadImageOptions
              )
            })
          } else {
            this.setState({
              message: {
                open: true,
                header: 'Error',
                content: () => (
                  <UIKitModal.Body>
                    <p className='text-medium'>
                      Minimum required dimensions: {minWidth}x{minHeight}px. Please upload an image with a higher
                      resolution.
                    </p>
                  </UIKitModal.Body>
                )
              }
            })
          }
        })
      } else {
        this.setState({ file: accepted }, () => {
          if (this.props.uploadOnFileSelect) {
            this.uploadFilesToServer()
          }
        })
      }
    }

    // Check rejected files, e.g. file size exceed
    if (rejected.length > 0) {
      const overSizedFiles = []

      rejected.forEach((file) => {
        if (file.size > maxSize) {
          overSizedFiles.push(file)
        }
      })

      if (overSizedFiles) {
        this.setState({
          message: {
            open: true,
            header: `Maximum file size is ${Math.round(maxSize * 0.000001)}Mb(s)`,
            content: () => (
              <UIKitModal.Body>
                <ul className='error-list'>
                  {overSizedFiles.map((file) => {
                    if (file.size > maxSize) {
                      return (
                        <li key={shortid.generate()}>
                          {file.name} <strong>({Math.round(file.size * 0.000001)}Mb)</strong>
                        </li>
                      )
                    }
                    return null
                  })}
                </ul>
              </UIKitModal.Body>
            )
          }
        })
      }
    }
  }

  uploadFilesToServer = () => {
    this.setState({ uploadInProgress: true })

    const formData = new FormData()
    const { api, apiFormData, apiHeaders, tags } = this.props
    const { name, size, type } = this.state.file[0]
    let headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'File-Name': encodeURIComponent(name),
      'Content-disposition': `attachment; filename=${encodeURIComponent(name)}`,
      'File-Size': size,
      'Content-Type': type
    }

    if (apiHeaders) {
      headers = { headers, ...apiHeaders }
    }

    const uploaders = this.state.file.map((file) => {
      formData.append('file', file)

      Object.keys(apiFormData).forEach((key) => {
        formData.append(key, apiFormData[key])
      })

      // for bulk selection in cloudinary console
      tags && formData.append('tags', tags)

      return httpApi.post(api, formData, headers)
    })

    axios.all(uploaders).then((resolve) => {
      const resolveData = resolve?.length === 1 ? resolve[0].data : resolve
      this.setState({ uploadedFile: resolve, uploadInProgress: false })
      if (typeof this.props.onFileUploadSuccess === 'function') {
        this.props.onFileUploadSuccess(this.props, this.state.file[0].name, resolveData)
      }
    })
  }

  onMessageModalClose = () => {
    this.setState({ message: { ...this.defaultMessageState } })
  }

  resetUploader = (e) => {
    if (typeof this.props.onFileInputReset === 'function') {
      this.props.onFileInputReset(this.props).then((response) => {
        if (response.ok) {
          this.setState({ file: null, uploadedFile: null })
          typeof this.props.resetCallback === 'function' && this.props.resetCallback()
        }
      })
    } else if (typeof this.props.onDeleteFile === 'function') {
      this.props.onDeleteFile(this.props)
      this.setState({ file: null, uploadedFile: null })
      typeof this.props.resetCallback === 'function' && this.props.resetCallback()
    } else {
      this.setState({ file: null, uploadedFile: null })
    }
    e && e.preventDefault()
  }

  render() {
    const { props, state } = this
    const ErrorModalContent = state.message.content || ''
    const documentName = (state.uploadedFile && state.file && state.file[0].name) || props.defaultInputValue || ''

    return (
      <div className={props.className}>
        <Dropzone
          name={props.name}
          id={props.id}
          accept={props.accept}
          tags={props.tags}
          maxSize={props.maxSize}
          multiple={props.multiple}
          ref={(node) => {
            state.ref === null && this.setState({ ref: node })
          }}
          onDrop={this.onFileSelect}
          style={{ display: 'none' }}
          {...props.dropzone}
        />
        {typeof props.children === 'function' &&
          props.children(
            state.ref,
            state.file,
            state.uploadedFile,
            state.uploadInProgress,
            this.resetUploader,
            this.uploadFilesToServer
          )}

        {props.showFileInput && (
          <>
            <InputStyled className={`${this.props.fileUploaderTheme ? 'fileuploader' : ''}`}>
              <div className='uploader input'>
                <input
                  type='text'
                  value={documentName}
                  aria-label={props.ariaLabel}
                />
                <i aria-hidden='true' className='icon'></i>
              </div>
              {
                documentName && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href='#'
                    title={this.props.removeFileLabel || 'Remove file'}
                    onClick={(e) => {
                      this.resetUploader(e)
                    }}
                  >
                    <Icon name='close' className='removeApplicationFile' />
                  </a>
                )
              }
              <Button
                onClick={() => {
                  state.ref.open()
                }}
                disabled={state.uploadInProgress}
                isLoading={state.uploadInProgress}
                type='button'
              >
                {this.props.uploadButtonLabel || 'Upload'}
              </Button>
            </InputStyled>
            {/* OLD CODE PRE-UIKIT (using semantic) */}
            {/* <Input action className={`${this.props.fileUploaderTheme ? 'fileuploader' : ''}`}>
              <Input
                aria-label={props.ariaLabel}
                value={documentName}
                icon={
                  documentName && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href='#'
                      title={this.props.removeFileLabel || 'Remove file'}
                      onClick={(e) => {
                        this.resetUploader(e)
                      }}
                    >
                      <Icon name='close' className='removeApplicationFile' />
                    </a>
                  )
                }
              />
              <Button
                onClick={() => {
                  state.ref.open()
                }}
                disabled={state.uploadInProgress}
                isLoading={state.uploadInProgress}
                type='button'
              >
                {this.props.uploadButtonLabel || 'Upload'}
              </Button>
            </Input> */}
          </>
        )}

        <UIKitModal show={state.message.open} size='md' onHide={this.onMessageModalClose}>
          <UIKitModal.Header
            title={this.props.uploadToFuturetek ? 'Press Accreditation' : state.message.header}
            onHide={() => this.setState({ ...this.state, message: { ...this.state.message, open: false } })}
          ></UIKitModal.Header>
          <UIKitModal.Body>
            <ErrorModalContent />
          </UIKitModal.Body>
          <UIKitModal.Footer>
            <Button
              variant='primary'
              onClick={() => this.setState({ ...this.state, message: { ...this.state.message, open: false } })}
              className='ms-auto'
            >
              OK
            </Button>
          </UIKitModal.Footer>
        </UIKitModal>
      </div>
    )
  }
}

const InputStyled = styled.div`
  width: 100%;
  min-height: 48px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: inline-flex;
  color: #444749;

  .uploader.input {
    width: 100%;
    position: relative;
    font-weight: 400;
    font-style: normal;
    display: inline-flex;
    color: #444749;

    > input {
      width: 100%;
    }
  }
`

export default styled(Uploader)`
  .removeApplicationFile {
    stroke: var(--bs-mch-black);
    width: ${px2rem(20)};
    margin-right: ${px2rem(15)};
    margin-top: ${px2rem(11)};
  }
`
