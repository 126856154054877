// @ts-nocheck --> First commit to TSX
import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Group from './Group'
import Grid, { GridPropTypes } from './Grid'

// If hide parent element of VirtualGrid by style, you should set visible property false.
// Otherwise, react-virtualized doesn't calculate heights correctly, because of JS.
const VirtualGrid = memo(({ items, groupBy, renderGroupTitle, marginTop, visible = true, gridStyle,
  stopRedraw, ...props }) => {
  const [groupedItems, setGroupedItems] = useState(null)
  useEffect(() => {
    if (groupBy && items && items.length) {
      const newGroupedItems = {}

      items.forEach((item) => {
        const groupKey = item[groupBy]
        newGroupedItems[groupKey] = [...(newGroupedItems[groupKey] || []), item]
      })

      setGroupedItems(newGroupedItems)
    } else {
      setGroupedItems(null)
    }
  }, [items, groupBy])

  if (visible && items && items.length) {
    return groupedItems ? (
      Object.keys(groupedItems).map((groupKey) => (
        <Group key={groupKey} {...{ groupKey, renderGroupTitle, marginTop }}>
          <Grid
            items={groupedItems[groupKey]}
            total={items.length}
            stopRedraw={stopRedraw}
            {...props}
            gridStyle={gridStyle}
          />
        </Group>
      ))
    ) : (
      <Grid items={items} stopRedraw={stopRedraw} {...props} gridStyle={gridStyle} />
    )
  }
  return null
})
VirtualGrid.displayName = 'VirtualGrid'

VirtualGrid.propTypes = {
  ...GridPropTypes,
  groupBy: PropTypes.string,
  renderGroupTitle: PropTypes.func,
  visible: PropTypes.bool
}

export default VirtualGrid
