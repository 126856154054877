import styled from 'styled-components'
import PropTypes from 'prop-types'

import ArtworkItem from '@modules/Catalog/Artworks/ArtworkItem'
import { Loader, VirtualGrid } from '@components'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

import TabContentHeader from './TabContentHeader'

const ArtworksTab = (props) => {
  const {
    editPreviewCollectionItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    size,
    isLoadingMore,
    totalItems,
    isTabExist
  } = props
  //@ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { onSelectCard } = useCollectionUtils()

  const isIntersectionActive = editPreviewCollectionItems && !editPreviewCollectionItemsValidating
    && !isRefreshing && !isEmpty && !isLoadingInitialData && !isLoadingMore

  const parseList = (list) => {
    const data = list.status.hidden ? list.summary : list.item
    const item = list?.status.hidden ?
      {
        images: [{ url: data?.image }],
        galleryName: data?.description,
        sponsorArtists: [{ artistName: data?.extraInfo1 }],
        showFullName: data?.showFullName,
        showYear: data?.extraInfo16,
        entityId: list.entityId
      } : data
    return item
  }

  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels?.Collections.artworkSubHeadingLabel}
        isTabExist={isTabExist}
      />
      {editPreviewCollectionItems.length > 0 &&
        <>
          <VirtualGrid
            //@ts-ignore
            items={editPreviewCollectionItems}
            stopRedraw={isOrganizeMode || !isIntersectionActive}
            renderItem={(list) => {
              if (!list.status) { return null } // just in case BE API is not working
              if (list && (list?.item || (list?.status?.hidden))) {
                const artworkData = parseList(list)
                return (
                  <ArtworkItem
                    {...artworkData}
                    isOrganizeMode={isOrganizeMode}
                    isCollectionArtwork
                    pageType='listing'
                    imageType='masonry'
                    unavailableToView={list?.status?.hidden}
                    comingFromCollection
                    unavailableCardLabel={labels?.Collections.notAvailableForViewLabel}
                    onSelectCard={onSelectCard}
                    isSelectedInCollections={typeof selectedItems?.find(x => x.entityId === (artworkData.id || artworkData?.entityId)) !== 'undefined'}
                  />
                )
              } else {
                return null
              }
            }}
          />
          <IntersectionCapture
            onIntersect={() => setSize(size + 1)}
            active={isIntersectionActive}
            params={{ rootMargin: '0px' }}
          />
        </>
      }
      {(isLoadingInitialData || isLoadingMore) &&
        // @ts-ignore
        <LoaderContainer><Loader centered active /></LoaderContainer>
      }
    </div>
  )
}

ArtworksTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isRefreshing: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  totalItems: PropTypes.number,
  isTabExist: PropTypes.bool
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`

export default ArtworksTab
