import { useContext } from 'react'
import PropTypes from 'prop-types'

import { NotificationsContext } from '@providers/Notifications'
import CollectionFormFormik from './Form'
import { Modal } from '@mch-group/uikit-components'

const EditCollectionForm = (props) => {
  const { showNotification } = useContext(NotificationsContext)
  const mID = 'create-collection'

  return (
    <Modal
      show
      onHide={() => { }}
      className={mID}
    >
      <Modal.Header
        title={
          props.collectionDetail ?
            props.labels?.Collections.editCollectionPopupLabel : props.labels?.Collections.createNewCollectionLabel
        }
        onHide={() => props.setEditCollectionModalOpen(false)} />
      <Modal.Body>
        <CollectionFormFormik
          collectionFormLabels={props.labels?.CollectionForms}
          collectionLabels={props.labels?.Collections}
          setVisible={() => { props.setEditCollectionModalOpen(false) }}
          submitFormCallback={(data, values) => {
            props.mutateCollectionDetail({
              ...props.collectionDetail,
              name: values.name,
              description: values.description
            }, false)
            props.setEditCollectionModalOpen(false)
            // @ts-ignore
            showNotification({
              content: `${values.name} ${props.labels?.Collections.successfulUpdateLabel}`
            })
          }}
          collectionDelete={props.removeCollection}
          collectionDetail={props.collectionDetail}
          submitCallback={props.submitCallback}
        />
      </Modal.Body>
    </Modal>
  )
}

EditCollectionForm.propTypes = {
  collectionDetail: PropTypes.object,
  labels: PropTypes.object,
  setEditCollectionModalOpen: PropTypes.func,
  removeCollection: PropTypes.func,
  mutateCollectionDetail: PropTypes.func,
  submitCallback: PropTypes.func
}

export default EditCollectionForm
