import React from 'react'
import { withFormik } from 'formik'
import SelectorControls from '@components/SelectorControls'
import PropTypes from 'prop-types'
import InputFeedback from '@components/Form/InputFeedback'
import styled from 'styled-components'
import { px2rem, mq, getSiteLanguage } from '@utils'
import { Collections as collectionsService, Translations as TranslationsService } from '@services'
import { logger } from '@dmi-mch/utils'
import CallToAction from '@reusable-modules/common/CallToAction'
import * as Yup from 'yup'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { Text, TextArea } from '@mch-group/uikit-components'

const CreateEditCollectionForm = props => {
  const {
    handleSubmit,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    collectionLabels,
    collectionFormLabels,
    setVisible,
    isSubmitting,
    collectionDetail,
    collectionDelete
  } = props
  const { id, name, numFollowers } = collectionDetail

  return (
    <StyledForm>
      <form>
        <div
          id='form-field'
          className='collection-field mb-8'
        >
          <Text
            label={collectionLabels?.nameLabel}
            type='text'
            id='collection-name'
            name='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className='collection-name'
          />
          {errors.name && touched.name
            ? <InputFeedback>{errors.name}</InputFeedback>
            : <span className='label label-3 color-textgrey'>{collectionLabels?.nameLimitLabel}</span>
          }
        </div>
        <div className='collection-field'>
          <TextArea
            label={collectionLabels?.descriptionLabel}
            name='description'
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={collectionLabels?.hintOnDescription}
            countChar
            maxCharacters={500}
            id='description'
            value={values.description}
          />
        </div>
        {collectionDetail &&
          <CallToActionStyled
            className='color-red'
            onClick={async () => {
              let subText = ''
              if (numFollowers > 0) {
                const subTextLabel = await TranslationsService.getDynamicLabelValue('Collections', 'deleteFollowedCollectionAlertMsgLabel', getSiteLanguage(), numFollowers)
                // @ts-ignore
                subText = subTextLabel.data.value
              }
              setVisible() //close the edit collection popup first
              collectionDelete(id, name, subText, props.submitCallback)
            }}
            hideArrow
            bold
            cursor='pointer'
            textDecoration
          >
            {collectionLabels?.deleteLabelOnPopUp}
          </CallToActionStyled>
        }
        <SelectorControlsStyled
          resetBtnActive
          onChange={handleSubmit}
          onClickReset={() => setVisible(false)}
          labels={{
            reset: collectionLabels?.cancelButtonLabel,
            apply: collectionDetail ? collectionFormLabels?.saveButtonLabel : collectionLabels?.createButtonLabel
          }}
          buttonType='submit'
          isLoading={isSubmitting}
          disabled={isSubmitting}
        />
      </form>
    </StyledForm>
  )
}

const StyledForm = styled.div`
  label.description {
    margin-bottom: ${px2rem(10)} !important;
  }

  .form .collection-field {
    &.field {
      ${mq.greaterThan('desktop')`
        padding: 0 ${px2rem(22)} 0 ${px2rem(23)};
      `}

      ${mq.lessThan('largestTablet')`
        padding: 8px ${px2rem(16)};
      `}
    }
  }

  .label.label-3 {
    font-family: inherit;
  }

  .collection-description {
    margin-bottom: 0 !important;
    margin-top: ${px2rem(10)};

    textarea {
      font-family: inherit;
      resize: none;
      height: ${px2rem(120)};
      padding-left: ${px2rem(8)};
      font-weight: ${tokens.fontWeights.artBaselText1.value};
      color: var(--bs-mch-gray-400);
    }
  }
`

const CallToActionStyled = styled(CallToAction)``

const SelectorControlsStyled = styled(SelectorControls)`
  border-top: 1px solid var(--bs-mch-galleries);
  margin-top: ${px2rem(47)};
  padding: ${px2rem(22)} ${px2rem(22)} 0 ${px2rem(23)};
`

CreateEditCollectionForm.propTypes = {
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  collectionLabels: PropTypes.object,
  setVisible: PropTypes.func,
  isSubmitting: PropTypes.bool,
  collectionFormLabels: PropTypes.object,
  collectionDetail: PropTypes.object,
  collectionDelete: PropTypes.func,
  submitCallback: PropTypes.func
}

export default withFormik({
  mapPropsToValues: ({ collectionDetail }: any) => ({
    name: collectionDetail?.name || '',
    description: collectionDetail?.description || ''
  }),
  displayName: 'myDetails',
  validationSchema: props =>
    Yup.object().shape({
      name: Yup.string()
        .max(30, props.collectionLabels?.nameErrorMessage)
        .required('This field is required')
        .trim(),
      description: Yup.string()
        .max(500, props.collectionLabels?.descriptionCharacterLimit)
    }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const collectionId = props.collectionDetail?.id
    setSubmitting(true)
    const reqParams = {
      'properties': [
        {
          'name': 'name',
          'value': values.name
        },
        {
          'name': 'description',
          'value': values.description
        }
      ]
    }
    try {
      const isCreating = !collectionId
      const response = isCreating
        ? await collectionsService.create('MANUAL', reqParams)
        : await collectionsService.edit(collectionId, reqParams)
      if (response.ok) {
        props.submitFormCallback(response.data, values, isCreating, collectionId)
      }
      else {
        // @Todo - handle error here
        props.setVisible()
      }
    } catch (e) { logger(e) }
    setSubmitting(false)
  }
})(CreateEditCollectionForm)
