import Cookies from 'js-cookie'
import useRequestLabelsPage from '@modules/collections/hooks/useRequestLabelsPage'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { ModuleTitle } from '@components/collections'
import { CollectionFormContext } from '@components/collections/collection-form'
import useMyCollectionsData from '@modules/collections/hooks/useMyCollectionsData'
import useSubscribeCollection from '@modules/collections/hooks/useSubscribeCollection'
import Tabs from './my-collections/Tabs'
import MyCollectionsTab from './my-collections/Tabs/MyCollectionsTab'
import SubscribedCollectionsTab from './my-collections/Tabs/SubscribedCollectionsTab'
import { Container } from '@mch-group/uikit-components'
import { useContext, useEffect } from 'react'

/**
 * MyCollectionsModule is a parent component for all the inner comments
 * includes Module Title + Add to Collection + My Favorites Card + Collections Cards
 * "myCollections" fetch the collections
 * "myFavoritesCollection" fetch the favorites
 */

const MyCollectionsModule = () => {
  // Retrieves labels and puts in the context
  useRequestLabelsPage()
  // @ts-ignore
  const [{ CMSProps, router, user, myCollectionPageActiveTab: activeTab }, dispatch] = useMyCollectionsPageContext()
  const { mcm_grp1_title } = CMSProps
  const { showForm }: any = useContext(CollectionFormContext)
  const {
    myFavoritesCollection,
    myCollections,
    isLoadingInitialData,
    myCollectionsValidating,
    isRefreshing,
    isLoadingMore,
    size,
    setSize,
    isEmpty
  } = useMyCollectionsData(true)

  const {
    subscribedCollectionsItems,
    subscribedCollectionsValidating,
    isRefreshing: isRefreshingSubscribedCollection,
    isLoadingMore: isLoadingMoreSubscribedCollection,
    size: pageSize,
    setSize: setPageSize,
    isEmpty: isSubscribedCollectionEmpty,
    isLoadingInitialData: isLoadingInitialSubscribedCollection
  } = useSubscribeCollection(true)

  //redirect to my collection tab if user delete all the subscribed collections
  useEffect(() => {
    if (
      subscribedCollectionsItems?.length === 0 &&
      activeTab === 'followingCollections' &&
      !isLoadingInitialSubscribedCollection
    ) {
      dispatch({ type: 'SET_MY_COLLECTION_ACTIVE_TAB', payload: 'myCollections' })
      const newUrl = `${window.location.pathname}?activeCollectionTab=myCollections`
      router.push({ pathname: '/' }, newUrl, { shallow: true, scroll: false })
    }
  }, [subscribedCollectionsItems, activeTab, dispatch, router, isLoadingInitialSubscribedCollection])

  useEffect(() => {
    Cookies.remove('__INSIDE__ROOM____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('_INSIDE__OS__COMPACT__ROOM_____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('_INSIDE__OS__COMPACT__SPECIAL__ROOM_____BACK__NAVIGATION__', { path: '/' })
  }, [])

  if (user) {
    return (
      <Container className='px-8'>
        <ModuleTitle title={mcm_grp1_title} />
        {/* Tabs Buttons */}
        {/* @ts-ignore */}
        <Tabs myCollections={myCollections} subscribedCollectionsItems={subscribedCollectionsItems} />
        {activeTab === 'myCollections' && (
          <MyCollectionsTab
            myFavoritesCollection={myFavoritesCollection}
            myCollections={myCollections}
            isLoadingInitialData={isLoadingInitialData}
            myCollectionsValidating={myCollectionsValidating}
            isRefreshing={isRefreshing}
            isLoadingMore={isLoadingMore}
            size={size}
            setSize={setSize}
            isEmpty={isEmpty}
            onClick={() => {
              showForm()
            }}
          />
        )}
        {activeTab === 'followingCollections' && (
          <SubscribedCollectionsTab
            subscribedCollectionsItems={subscribedCollectionsItems}
            isLoadingInitialData={isLoadingInitialSubscribedCollection}
            subscribedCollectionsValidating={subscribedCollectionsValidating}
            isRefreshing={isRefreshingSubscribedCollection}
            isLoadingMore={isLoadingMoreSubscribedCollection}
            size={pageSize}
            setSize={setPageSize}
            isEmpty={isSubscribedCollectionEmpty}
          />
        )}
      </Container>
    )
  }
  return null
}

export default MyCollectionsModule
