import PropTypes from 'prop-types'
import styled from 'styled-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { px2rem } from '@utils'
import { Icon } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const CollectionCardSmall = ({ item, isChecked, ...props }) => {
  const { thumbnailImageUrls, itemsCount, name } = item
  return (
    <ListStyled {...props}>
      {thumbnailImageUrls && thumbnailImageUrls[0] ? (
        <img
          loading='lazy'
          src={uploadTransformationUrl(thumbnailImageUrls[0], 'w_70,q_auto:good,dpr_1.0,ar_1,c_crop,c_fill,g_auto', {
            transformUrl: cloudinary.throughCloudfront
          })}
          width={70}
          height={70}
          alt={name}
        />
      ) : (
        <EmptyCollectionStyled />
      )}

      <div className='d-flex w-100 ps-6 justify-content-between align-items-center'>
        <div className='d-flex flex-column justify-content-center'>
          <h6 className='p-0 m-0'>{name}</h6>
          <p className='p-0 m-0'>{itemsCount} items</p>
        </div>
        <ClickToAction>{isChecked ? <Icon name='checkmark' /> : <Icon name='add' />}</ClickToAction>
      </div>
    </ListStyled>
  )
}

CollectionCardSmall.propTypes = {
  item: PropTypes.object,
  isChecked: PropTypes.bool
}

const ListStyled = styled.div`
  display: flex !important;
  align-items: center;
  cursor: pointer;
  padding: ${px2rem(10)} ${px2rem(15)} ${px2rem(10)} ${px2rem(10)} !important;

  img {
    width: ${px2rem(70)};
  }

  .content {
    flex-grow: 1;
    padding: 0 0 0 ${px2rem(18)} !important;
  }

  &:hover {
    background: var(--bs-mch-galleries);
  }
`

const EmptyCollectionStyled = styled.div`
  width: ${px2rem(70)};
  height: ${px2rem(70)};
  background: ${tokens.color.light.base.neutrals['200'].value};
`

const ClickToAction = styled.div`
  width: ${px2rem(20)};
`

export default CollectionCardSmall
